import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ReportError({ error }) {
  const navigate = useNavigate();
  return (
    <Modal
      show
      onHide={() => navigate('/dashboard')}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Unable to retrieve report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p>{error}</p>}
        <p>
          Please select the "Back" button to return to the
          dashboard.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => navigate('/dashboard')}>Back</Button>
      </Modal.Footer>
    </Modal>
  );
}

ReportError.propTypes = {
  error: PropTypes.string,
};

ReportError.defaultProps = {
  error: null,
};
