import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ScorePill({ score }) {
  let variant = 'danger';
  if (score >= 4) {
    variant = 'success';
  } else if (score >= 3.5) {
    variant = 'warning';
  }

  return (
    <Badge pill bg="light" text={variant} style={{ maxWidth: '30px' }}>
      {Number.isNaN(Number(score)) ? score : Number(score).toFixed(2)}
    </Badge>
  );
}

ScorePill.propTypes = {
  score: PropTypes.any,
};

export default ScorePill;
