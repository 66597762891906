import React, { useEffect, useState } from 'react';
import {
  FormCheck,
} from 'react-bootstrap';
import { merge } from 'lodash-es';
import PropTypes from 'prop-types';

function PeerTableRow({
  scoringKey, peer, response, onChanged, hideFirstColumn,
}) {
  const values = [0, 1, 2, 3, 4, 5].map((num) => String(num));
  const [updatedResponse, setUpdatedResponse] = useState(response);

  useEffect(() => {
    setUpdatedResponse(response);
  }, [response]);

  return (
    <tr>
      <th>{`${peer.firstName} ${peer.lastName ? peer.lastName : ''}`}</th>
      {values.map((val, index) => (
        <td key={`cell-${scoringKey}-${peer.respondentId}-${val}`}>
          {(!hideFirstColumn || index !== 0) && (
            <FormCheck
              className="text-center"
              type="radio"
              name={`${scoringKey}-${peer.respondentId}`}
              value={val}
              onChange={() => {
                setUpdatedResponse(merge({ ...response }, { [scoringKey]: { [peer.respondentId]: val } }));
                onChanged({ [scoringKey]: { [peer.respondentId]: val } });
              }}
              checked={val === (updatedResponse[scoringKey] || {})[peer.respondentId]}
            />
          )}

        </td>
      ))}
    </tr>
  );
}

PeerTableRow.propTypes = {
  scoringKey: PropTypes.string,
  peer: PropTypes.objectOf(PropTypes.string),
  response: PropTypes.object,
  onChanged: PropTypes.func,
  hideFirstColumn: PropTypes.bool,
};

PeerTableRow.defaultProps = {
  scoringKey: '',
  peer: {},
  response: {},
  onChanged: () => {},
  hideFirstColumn: false,
};

export default PeerTableRow;
