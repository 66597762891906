import React, { useState } from 'react';
import { BarChart as eBarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import PropTypes from 'prop-types';

import { ITPMETRICS_BLUE, ITPMETRICS_YELLOW } from '../common/Colours';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  eBarChart,
  CanvasRenderer,
  LegendComponent,
  MarkLineComponent,
]);

export function ReportPersonalityBarChart({ label, data }) {
  const [chartImage, setChartImage] = useState(null);

  const getOption = () => ({
    animation: false,
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: 'white',
      borderColor: 'black',
      textStyle: { color: 'dark' },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none',
      },
    },
    xAxis: [{
      type: 'value',
      boundaryGap: '0%',
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: false },
      splitLine: { show: true },
      splitNumber: 10,
      max: 100,
    }, {
      position: 'bottom',
      axisLine: { show: false },
      axisTick: { show: false },
      data: ['Low', 'Moderate', 'High'],
    }],
    yAxis: {
      type: 'category',
      data: label,
      boundaryGap: '0%',
      axisLabel: {
        formatter: (value) => value.substring(0, value.length),
        show: true,
        margin: 15,
      },
      splitLine: {
        show: false,
        lineStyle: {},
      },
      axisTick: { show: true },
      axisLine: {
        lineStyle: {},
      },
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data,
        markLine: {
          symbol: 'none',
          data: [{
            xAxis: 25,
            label: { show: false, formatter: () => '' },
            lineStyle: { color: ITPMETRICS_YELLOW, width: 2, type: 'dotted' },
          }, {
            xAxis: 75,
            label: { show: false, formatter: () => '' },
            lineStyle: { color: ITPMETRICS_YELLOW, width: 2, type: 'dotted' },
          }],
        },
        itemStyle: {
          borderRadius: [0, 3, 3, 0],
          color: (params) => {
            if (params.dataIndex === data.length - 1) {
              return ITPMETRICS_YELLOW;
            }
            return ITPMETRICS_BLUE;
          },
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true,
        },
      },
    ],
    grid: {
      containLabel: true,
      right: '0px',
      left: '0px',
      bottom: '10px',
      top: '0px',
    },
  });
  return (
    <>
      <img alt="bar-chart" src={chartImage} style={{ width: '100%' }} className="print-only" />
      <ReactEChartsCore
        className="no-print"
        echarts={echarts}
        option={getOption()}
        style={{ height: '20em' }}
        ref={(e) => {
          if (e) {
            setChartImage(e.getEchartsInstance().getDataURL());
          }
        }}
      />
    </>

  );
}

ReportPersonalityBarChart.propTypes = {
  label: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.number),
};

ReportPersonalityBarChart.defaultProps = {
  label: null,
  data: null,
};
