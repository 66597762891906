import React from 'react';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { __ } from '../../../helpers/translations';

export const conflictSections = {
  Dominating: {
    key: 'Dominating',
    caption: __('G253'),
    labelPlacement: 'top',
    color: '#030e1e',
  },
  Integrating: {
    key: 'Integrating',
    caption: __('G249'),
    labelPlacement: 'top',
    color: '#828c9a',
  },
  Avoiding: {
    key: 'Avoiding',
    caption: __('G252'),
    labelPlacement: 'bottom',
    color: '#222b39',
  },
  Accommodating: {
    key: 'Accommodating',
    caption: __('G250'),
    labelPlacement: 'bottom',
    color: '#293a55',
  },
  Compromising: {
    key: 'Compromising',
    caption: __('G251'),
    labelPlacement: 'top',
    color: '#a0a29e',
  },
};

export function ReportConflictConcernChart({ activeKey }) {
  const tileOrderedEntries = Object.keys(conflictSections).map((key) => conflictSections[key]);
  const getBackground = (key) => {
    if (!activeKey || key === activeKey) {
      return conflictSections[key].color;
    }
    return 'white';
  };

  return (
    <table className="report-conflict-chart-table report-conflict-chart-center-header me-6">
      <tbody>
        <tr>
          <td>{' '}</td>
          <td>
            <table width="100%">
              <tbody>
                <tr>
                  <td colSpan={2} className="report-conflict-chart-table-label">
                    <h4>
                      {__('G254')}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td className="report-conflict-chart-table-label">{__('G255')}</td>
                  <td className="report-conflict-chart-table-label">{__('G256')}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td rowSpan={2} className="report-conflict-chart-table-label report-conflict-chart-rotate">
                    <h4>{__('G257')}</h4>
                  </td>
                  <td className="report-conflict-chart-table-label report-conflict-chart-rotate report-conflict-chart-table-cell">
                    {__('G256')}
                  </td>
                </tr>
                <tr>
                  <td className="report-conflict-chart-table-label report-conflict-chart-rotate report-conflict-chart-table-cell">
                    {__('G255')}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <Row>
              <Col className="report-conflict-chart-container-col">
                <div className="report-conflict-chart-container justify-content-center">
                  {tileOrderedEntries.map(
                    ({ caption, labelPlacement, key }) => (
                      <div
                        key={`report-conflictmanagement-${key}-${activeKey}`}
                        className={classNames([
                          'report-conflict-chart-shape',
                          { 'report-center-shape': key === 'Compromising' },
                          { 'report-conflict-chart-shape-primary': key === activeKey },
                        ])}
                        style={{ backgroundColor: getBackground(key) }}
                      >
                        {labelPlacement === 'top'
                        && (
                          <h4 className="report-center-text mt-3">
                              {caption}
                          </h4>
                        )}

                        {labelPlacement === 'bottom'
                        && (
                        <h4 className="report-center-text report-center-text-bottom mt-6">
                          {caption}
                        </h4>
                        )}
                      </div>
                    ),
                  )}
                </div>
              </Col>
            </Row>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

ReportConflictConcernChart.propTypes = {
  activeKey: PropTypes.string,
  isTemplate: PropTypes.bool,
  report: PropTypes.shape({
    Dominating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      Description: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Avoiding: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      Description: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Integrating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      Description: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Accommodating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      Description: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Compromising: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      Description: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
  }),
};
