import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { getLegacyReports, getReviews, getUserSurveys } from '../../../bridge';
import { redirectToRespondent } from '../../../helpers/itpmetricsUtils';
import { logError } from '../../../helpers/logger';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ContentSection from '../common/ContentSection';
import Loading from '../common/Loading';
import Page from '../common/Page';
import SignedOut from '../common/SignedOutDialog';
import SurveyNotFound from '../common/SurveyNotFound';
import LockScreen from '../verification/LockScreen';

import DashboardCard from './DashboardCard';
import LegacyReportCard from './LegacyReportCard';

function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);
  const [respondents, setRespondents] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [legacyReports, setLegacyReports] = useState([]);
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuthContext();

  useEffect(() => {
    const fetchSurveys = async () => {
      const { error: err, response } = await getUserSurveys();
      if (err) {
        logError('dashboard_fetch_surveys', err);
        setError(err);
        return;
      }
      const result = await redirectToRespondent();
      if (result === false) {
        setIsUnavailable(true);
        return;
      }

      setSurveys(response.filter((r) => r.type === 'survey'));
      setRespondents(response.filter((r) => r.type === 'respondent'));

      setIsLoading(false);
    };

    const fetchReviews = async () => {
      const { error: err, response } = await getReviews();
      if (err) {
        logError('dashboard_fetch_reviews', err);
        return;
      }
      setReviews(response);
    };

    const fetchLegacyReports = async () => {
      const { error: err, response } = await getLegacyReports();
      if (err) {
        logError('dashboard_fetch_legacy_reports', err);
        return;
      }
      setLegacyReports(response);
    };

    if (!user) {
      return;
    }
    if (!user.policyId) {
      navigate('/profile');
    } else {
      fetchSurveys();
      fetchReviews();
      fetchLegacyReports();
    }
  }, [user]);

  if (error) {
    return <LockScreen show />;
  }

  if (isUnavailable) {
    return <SurveyNotFound />;
  }
  if (!user) {
    return <SignedOut />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      {!respondents?.length && !surveys?.length && (
        <ContentSection>
          <Alert variant="warning" className="text-center">
            {`There are no surveys assigned to ${user.email}.`}
            <br />
            <br />
            If you are expecting to take a survey for your course or employer, please contact them about the email address they used for you.
          </Alert>
        </ContentSection>
      )}
      <br />
      {respondents && respondents.length > 0 && (
        <ContentSection tightSpacing>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {respondents.map((item) => (
              <DashboardCard
                key={`dashboard-respondent-${item.type}-${item.respondentId}`}
                item={item}
              />
            ))}
          </div>
        </ContentSection>
      )}
      {surveys && surveys.length > 0 && (
        <ContentSection tightSpacing>
          <hr />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {surveys.map((item) => (
              <DashboardCard
                key={`dashboard-survey-${item.type}-${item.surveyId}`}
                item={item}
              />
            ))}
          </div>
        </ContentSection>
      )}
      {reviews && reviews.length > 0 && (
        <ContentSection tightSpacing>
          <hr />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {reviews.map((item) => (
              <DashboardCard
                key={`dashboard-review-${item.type}-${item.reviewId}`}
                item={item}
              />
            ))}
          </div>
        </ContentSection>
      )}
      {legacyReports && legacyReports.length > 0 && (
        <ContentSection tightSpacing>
          <hr />
          <h4>Legacy Reports</h4>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {legacyReports.map((item) => (
              <LegacyReportCard
                key={`dashboard-legacy-${item.respondentId}`}
                item={item}
              />
            ))}
          </div>
        </ContentSection>
      )}
    </Page>
  );
}

export default Dashboard;
