/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormCheck } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

function ConsentOptions({
  data,
  response,
  onChanged,
}) {
  const items = data.filter(({ type }) => type === 'consentOptions');
  if (items.length === 0) {
    return null;
  }

  return (
    <>
      {items.map((item) => (
        <div key={`consent-options-${item.key}`}>
          <div className="p-3">
            {Object.values(item.contentList).map(
              (content, index) => (
                <div key={`consent-options-${item.key}-${index}`} className="pb-4">
                  {index === item.radioListIndex && Object.keys(item.radioList).map((key) => (
                    <div key={`consent-${item.key}-${index}-${key}`} className="pb-4">
                      <FormCheck
                        type="radio"
                        key={`consent-options-${item.key}-${index}-${key}-radio`}
                        id={`consent-options-${item.key}-${index}-${key}-radio`}
                        name={key}
                        value={key}
                        label={item.radioList[key]}
                        checked={response && response[item.key] === key}
                        onChange={() => onChanged({ [item.key]: key })}
                      />
                    </div>
                  ))}
                  {ReactHtmlParser(content)}
                </div>
              ),
            )}
          </div>
        </div>

      ))}
    </>
  );
}

ConsentOptions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      contentList: PropTypes.array,
      type: PropTypes.string,
    }),
  ),
  response: PropTypes.object,
  onChanged: PropTypes.func,
};

ConsentOptions.defaultProps = {
  data: [],
  response: {},
  onChanged: () => {},
};

export default ConsentOptions;
