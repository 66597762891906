import React from 'react';
import { createRoot } from 'react-dom/client';
import * as amplitude from '@amplitude/analytics-browser';

import './helpers/initFA';

import App from './App';
import { settings } from './config';
import Main from './Main';

amplitude.init(settings.amplitudeKey, {
  defaultTracking: true,
});

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <Main>
    <App />
  </Main>,
);
