import React from 'react';
import { Card } from 'react-bootstrap';
import ClampLines from 'react-clamp-lines';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formatDate } from '../../../helpers/itpmetricsUtils';

import './DashboardCard.scss';

function LegacyReportCard({ item }) {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`/legacy/report/${item.respondentId}`)}
      className="mx-2 my-3 dashboard-card"
    >
      <Card.Body>
        <>
          <ClampLines
            text={item.name}
            lines={2}
            buttons={false}
            className="dashboard-card-title"
          />
          <div style={{
            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.8em',
          }}
          >
            {item.templateName}
          </div>
          <div style={{
            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.7em',
          }}
          >
            {formatDate(item.dateCreated)}
          </div>
        </>
      </Card.Body>
    </Card>
  );
}

LegacyReportCard.propTypes = {
  item: PropTypes.object,
};

export default LegacyReportCard;
