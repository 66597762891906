import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { sortBy } from 'lodash-es';
import PropTypes from 'prop-types';

import { isIndividualTemplate } from '../../../../helpers/itpmetricsUtils';

import RespondentCard from './RespondentCard';
import TeamCard from './TeamCard';

export default function SurveyDashboardCards({
  respondents, template, surveyId, surveyStatus, onChanged, teams, releaseMode,
}) {
  const cards = isIndividualTemplate(template)
    ? respondents
    : sortBy(Object.keys(teams || {}).sort().map((key) => (
      { respondents: teams[key], teamName: key }
    )), (obj) => Number(obj.teamName) || obj.teamName);

  return (
    <Row className="pb-10">
      {cards.map((card) => (
        <Col
          className="px-1 col-same-height"
          key={`survey-card-${card.respondentId || card.teamName}`}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          {isIndividualTemplate(template) ? (
            <RespondentCard
              surveyId={surveyId}
              respondent={card}
              onChanged={onChanged}
            />
          ) : (
            <TeamCard
              surveyId={surveyId}
              teamName={card.teamName}
              respondents={card.respondents}
              onChanged={onChanged}
              releaseMode={releaseMode}
              surveyStatus={surveyStatus}
            />
          )}
        </Col>
      ))}
    </Row>
  );
}

SurveyDashboardCards.propTypes = {
  template: PropTypes.object,
  teams: PropTypes.object,
  respondents: PropTypes.array,
  surveyId: PropTypes.string,
  onChanged: PropTypes.func,
  releaseMode: PropTypes.string,
  surveyStatus: PropTypes.string,
};
