import React from 'react';
import PropTypes from 'prop-types';

import ErrorDialog from './ErrorDialog';

function NotFound({ subject }) {
  return (
    <ErrorDialog
      content={(
        <>
          <p className="lead text-800 font-sans-serif fw-semi-bold">
            {`The ${subject} you're looking for cannot be found.`}
          </p>
          <hr />
          <p>
            Make sure the address is correct and that the page hasn't moved.
            If you think this is a mistake, please contact support@itpmetrics.com.
          </p>
        </>
      )}
    />
  );
}

NotFound.propTypes = {
  subject: PropTypes.string,
};

NotFound.defaultProps = {
  subject: 'page',
};

export default NotFound;
