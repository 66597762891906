import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

import { DebouncedTextArea } from '../../../common/DebouncedTextArea';

function PeerRangedText({
  data,
  peers,
  response,
  onChanged,
}) {
  const items = data.filter((item) => item.type === 'peerRangedText');
  if (items.length === 0) {
    return null;
  }

  return (
    <>
      {items.map((item) => (
        <div key={`peer-ranged-text-${item.key}`}>
          <div className="p-3">{ReactHtmlParser(items[0].content)}</div>
          {peers.map((participant) => (
            <DebouncedTextArea
              key={`peer-ranged-text-${item.key}-${participant.respondentId}`}
              originalValue={(response[item.key] || {})[participant.respondentId] || ''}
              scoringKey={item.key}
              respondentId={participant.respondentId}
              content={`${participant.firstName} ${participant.lastName}`}
              onChanged={(key, id, value) => onChanged({ [key]: { [id]: value } })}
              unfamiliarCheckbox
            />
          )) }
        </div>

      ))}
    </>
  );
}

PeerRangedText.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  peers: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    respondentId: PropTypes.string,
  })),
  response: PropTypes.object,
  onChanged: PropTypes.func,
};

PeerRangedText.defaultProps = {
  data: [],
  peers: [
    { firstName: 'Peer', lastName: 'A', respondentId: 'A' },
    { firstName: 'Peer', lastName: 'B', respondentId: 'B' },
    { firstName: 'Peer', lastName: 'C', respondentId: 'C' },
  ],
  response: {},
  onChanged: () => {},
};

export default PeerRangedText;
