/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import conflictManagement from '../../../assets/itpmetrics/conflict-management-img.png';
import peer from '../../../assets/itpmetrics/peer-feedback-img.png';
import personality from '../../../assets/itpmetrics/personality-img.png';
import team from '../../../assets/itpmetrics/team-health-img.png';
import { __ } from '../../../helpers/translations';
import Section from '../../common/Section';

import CardService from './CardService';
import SectionHeader from './SectionHeader';

export const reviews = [
  [
    {
      title: __('G40'),
      type: __('G176'),
      description: __('G41'),
      image: peer,
    },
  ],
];

export const services = [
  [
    {
      title: __('G22'),
      id: 'teamPlayer',
      description: __('G23'),
      image: peer,
      type: __('G170'),
    },
    {
      title: __('G24'),
      id: 'teamPlayerFacets',
      description: __('G25'),
      image: peer,
      type: __('G170'),
    },
  ],
  [
    {
      title: __('G26'),
      id: 'entEffectiveness',
      description: __('G27'),
      image: peer,
      type: __('G170'),
    },
    {
      title: __('G28'),
      id: 'peerFeedback',
      description: __('G29'),
      image: peer,
      type: __('G170'),
    },
  ],
  [
    {
      title: __('G30'),
      id: 'teamDynamics',
      description: __('G31'),
      image: team,
      type: __('G171'),
    },
    {
      title: __('G32'),
      id: 'peerFeedbackTeamDynamics',
      description: __('G33'),
      image: team,
      type: __('G275'),
    },
  ],
  [
    {
      title: __('G34'),
      id: 'personality',
      description: __('G35'),
      image: personality,
      type: __('G172'),
    },
    {
      title: __('G36'),
      id: 'conflictManagement',
      description: __('G37'),
      image: conflictManagement,
      type: __('G172'),
    },
  ],
  [
    {
      title: __('G276'),
      id: 'entEffectivenessTeamDynamics',
      description: __('G27'),
      image: peer,
      type: __('G275'),
    },
  ],
];

function Services() {
  return (
    <Section bg="light" className="text-center">
      <SectionHeader
        title={__('G13')}
        subtitle=""
      />
      {services.map((row, rowIndex) => (
        <Row className="mt-6 g-6" key={`service-${rowIndex}`}>
          {row.map((service, columnIndex) => (
            <Col lg={6} className="mt-6 mt-lg-0" key={`service-${rowIndex}-${columnIndex}`}>
              <CardService {...service} />
            </Col>
          ))}
        </Row>
      ))}
    </Section>
  );
}

export default Services;
