import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { __ } from '../../../helpers/translations';

export const StatusMap = {
  'not-started': {
    color: 'light',
    text: __('G81'),
    textVariant: 'secondary',
  },
  'in-progress': {
    color: 'secondary',
    text: __('G82'),
    textVariant: 'light',
  },
  completed: {
    color: 'success',
    text: __('G83'),
    textVariant: 'light',
  },
  ended: {
    color: 'success',
    text: __('G84'),
    textVariant: 'light',
  },
};

function StatusPill({ status, surveyStatus }) {
  let bgVariant = StatusMap[status]?.color || 'light';
  let textVariant = StatusMap[status]?.textVariant || 'secondary';
  let text = StatusMap[status]?.text;
  if (surveyStatus && surveyStatus === 'ended' && status !== 'completed') {
    text = __('G85');
    bgVariant = 'light';
    textVariant = 'secondary';
  }
  return (
    <Badge pill bg={bgVariant} text={textVariant}>{text}</Badge>
  );
}

StatusPill.propTypes = {
  status: PropTypes.string,
  surveyStatus: PropTypes.string,
};

export default StatusPill;
