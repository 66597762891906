import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import conflictManagementImg from '../../../assets/itpmetrics/conflict-management-img.png';
import peerFeedbackImg from '../../../assets/itpmetrics/peer-feedback-img.png';
import personalityImg from '../../../assets/itpmetrics/personality-img.png';
import teamPlayerImg from '../../../assets/itpmetrics/team-health-img.png';
import { downloadLegacyReport, downloadReport, downloadTemplateReport } from '../../../bridge';
import { formatDate } from '../../../helpers/itpmetricsUtils';
import { __ } from '../../../helpers/translations';
import { ITPMETRICS_BLUE } from '../common/Colours';

export function ReportTitleSection({ respondent, templateMode }) {
  const template = {
    personality: {
      title: __('G196'),
      tagLine: __('G197'),
      imgSrc: personalityImg,
      imgAltText: 'personality-logo',
    },
    conflictManagement: {
      title: __('G198'),
      tagLine: __('G199'),
      imgSrc: conflictManagementImg,
      imgAltText: 'conflictManagement-logo',
    },
    teamPlayer: {
      title: __('G200'),
      tagLine: __('G201'),
      imgSrc: teamPlayerImg,
      imgAltText: 'team-player-logo',
    },
    teamPlayerFacets: {
      title: __('G202'),
      tagLine: __('G201'),
      imgSrc: teamPlayerImg,
      imgAltText: 'team-player-facets-logo',
    },
    peerFeedback: {
      title: __('G203'),
      tagLine: __('G204'),
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
    },
    peerFeedbackExpress: {
      title: __('G205'),
      tagLine: __('G204'),
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
    },
    peerFeedbackMedian: {
      title: __('G206'),
      tagLine: __('G204'),
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
    },
    teamDynamics: {
      title: __('G207'),
      tagLine: __('G208'),
      imgSrc: teamPlayerImg,
      imgAltText: 'team-player-logo',
    },
    peerFeedbackTeamDynamics: {
      title: __('G209'),
      tagLine: __('G204'),
      imgSrc: teamPlayerImg,
      imgAltText: 'team-player-logo',
    },
    entEffectiveness: {
      title: __('G210'),
      tagLine: __('G211'),
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
      hideInstitution: false,
    },
    entEffectivenessTeamDynamics: {
      title: __('G210'),
      tagLine: __('G211'),
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
      hideInstitution: false,
    },
  };
  const [isLoading, setLoading] = useState(false);
  const report = template[respondent.templateId] || {
    title: '',
    tagLine: '',
    imgSrc: null,
    imgAltText: '',
  };
  const onDownload = async () => {
    setLoading(true);
    // eslint-disable-next-line no-nested-ternary
    const { response } = templateMode
      ? await downloadTemplateReport(respondent.templateId)
      : respondent.legacy
        ? await downloadLegacyReport(respondent.respondentId)
        : await downloadReport(respondent.respondentId);
    if (!response) {
      Swal.fire({
        title: __('G212'),
        text: __('G213'),
        icon: 'error',
        confirmButtonColor: ITPMETRICS_BLUE,
        confirmButtonText: 'OK',
      });
      setLoading(false);
      return;
    }
    const blob = new Blob([response], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${report.title} - ${respondent.firstName} ${respondent.lastName}.pdf`;
    link.click();
    setLoading(false);
  };

  return (
    <Card className="py-3 my-3">
      <Card.Body>
        <div className="m-3 p-3">
          <div className="d-flex">
            {report.imgSrc && (
              <img
                src={report.imgSrc}
                alt={report.imgAltText}
                width="100"
                height="100"
                className="mx-3"
              />
            )}
            <span className="mx-3 my-4 primary report-title">{report.title}</span>
            <span className="d-flex report-download">
              <Button
                className="no-print"
                variant="primary"
                disabled={isLoading}
                onClick={!isLoading ? onDownload : null}
                style={{ whiteSpace: 'nowrap' }}
              >
                <FontAwesomeIcon icon={faDownload} />
                {isLoading ? `  ${__('G219')}` : `  ${__('G220')}`}
              </Button>
            </span>
          </div>
          <br />
          <h4>{report.tagLine}</h4>
          <br />
          <br />
          {__('G214')}
          <h5>
            {`${respondent.firstName} ${respondent.lastName}`}
            <br />
            {`${formatDate(respondent.completedAt)}`}
            <br />
          </h5>
          <br />
          <div>
            {!report.hideInstitution && (
            <>
              <br />
              Individual and Team Performance Lab
              <br />
              Department of Psychology
              <br />
              The Unviersity of Calgary
            </>
            )}

          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

ReportTitleSection.propTypes = {
  respondent: PropTypes.shape({
    respondentId: PropTypes.string,
    completedAt: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    templateId: PropTypes.string,
    legacy: PropTypes.bool,
  }),
  templateMode: PropTypes.bool,
};

ReportTitleSection.defaultProps = {
  respondent: null,
  templateMode: false,
};
