import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { updateUserResponses } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';
import { DebouncedTextArea } from '../../common/DebouncedTextArea';

function EditResponseModal({
  respondent, responseKey, teamMemberId, onClose, onChanged,
}) {
  return (
    <Modal size="lg" show centered onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{__('G166')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DebouncedTextArea
          scoringKey={responseKey}
          respondentId={teamMemberId}
          originalValue={teamMemberId
            ? (respondent.response[responseKey] || {})[teamMemberId] || ''
            : respondent.response[responseKey]}
          onChanged={
            async (key, id, value) => {
              const changes = teamMemberId
                ? { [key]: { [id]: value } }
                : { [key]: value };
              await updateUserResponses(respondent.respondentId, changes);
              await onChanged();
            }
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          {__('G167')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

EditResponseModal.propTypes = {
  surveyId: PropTypes.string,
  respondent: PropTypes.object,
  responseKey: PropTypes.string,
  teamMemberId: PropTypes.string,
  onClose: PropTypes.func,
  onChanged: PropTypes.func,
};

export default EditResponseModal;
