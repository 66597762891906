import React, { useEffect, useState } from 'react';
import { Container, ProgressBar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { getRespondent } from '../../../bridge';
import { findProgress } from '../../../helpers/itpmetricsUtils';
import { logError } from '../../../helpers/logger';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Loading from '../common/Loading';
import NotFound from '../common/NotFound';
import Page from '../common/Page';
import SignedOut from '../common/SignedOutDialog';

import SurveyBody from './components/SurveyBody';

function Survey() {
  const { user } = useAuthContext();
  const [template, setTemplate] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [peers, setPeers] = useState(null);
  const [respondent, setRespondent] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);
  const { respondentId, pageIndex: pageIndexStr } = useParams();
  const pageIndex = Number(pageIndexStr);

  useEffect(() => {
    const fetchRespondent = async () => {
      const {
        error, response,
      } = await getRespondent(respondentId);
      setLoading(false);

      if (error) {
        setLoading(false);
        logError('survey_get_respondent', error);
        return;
      }
      await setTemplate(response.template);
      await setTotal(response.count);
      await setPeers(response.peers);
      await setRespondent(response.respondent);
      await setSurvey(response.survey);
    };
    fetchRespondent();
  }, [respondentId]);

  if (!user) {
    return <SignedOut />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!template) {
    return <NotFound subject="template" />;
  }

  return (
    <Page>
      <Container fixed="true" style={{ minWidth: '300px' }}>
        <h1 className="py-2">
          {survey.name}
        </h1>
        <ProgressBar className="pb-2" now={findProgress(template, pageIndex, total)} />
        <SurveyBody
          template={template}
          respondentId={respondentId}
          pageIndex={pageIndex}
          respondent={respondent}
          peers={peers}
        />
      </Container>
    </Page>
  );
}

export default Survey;
