import React, { useState } from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { createSurvey } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';
import { services } from '../../landing/Services';

const templateInfoArray = services.flatMap((subArray) => subArray);

function SelectTemplateModal({ onTemplateSelected }) {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const onClickTemplate = (template) => {
    setSelectedTemplate(template);
  };

  const onSelected = async () => {
    const { response } = await createSurvey({ templateId: selectedTemplate.id });
    onTemplateSelected(response);
  };

  return (
    <Card>
      <Row className="justify-content-center p-4">
        <Col lg={5}>
          <Card.Header className="text-center fs-2 fw-bold">
            {__('G168')}
          </Card.Header>
          <Card.Body>
            {templateInfoArray.map((template) => (
              <div
                key={`template-${template.id}`}
                className="d-flex align-items-center mb-4"
                onClick={() => onClickTemplate(template)}
                role="button"
                tabIndex={0}
              >
                <div className="mr-3">
                  <img src={template.image} alt={template.title} className="me-3" width="50px" />
                </div>
                <div>
                  <h5>{template.title}</h5>
                </div>
              </div>
            ))}
          </Card.Body>
        </Col>
        <Col lg={7} className="p-4">
          {selectedTemplate && (
            <>
              <Row>
                <div className="text-center">
                  <img src={selectedTemplate.image} alt={selectedTemplate.title} />
                  <h4 className="mt-4 mb-3">{selectedTemplate.title}</h4>
                  <h6>{selectedTemplate.type.toUpperCase()}</h6>
                </div>
                {selectedTemplate.description && (
                <p className="mt-4">{selectedTemplate.description}</p>
                )}
              </Row>
              <Row className="justify-content-center py-4">
                <Col className="px-0">
                  <Link to={`/template/${selectedTemplate.id}/0`} target="_blank">
                    <Button className="btn btn-light">{__('G38')}</Button>
                  </Link>
                </Col>
                <Col className="px-0 justify-content-center" style={{ display: 'contents' }}>
                  <Link to={`/report/${selectedTemplate.id}/template`} target="_blank">
                    <Button className="btn btn-light">{__('G39')}</Button>
                  </Link>
                </Col>
                <Col className="px-0">
                  <Button
                    onClick={onSelected}
                    className="btn btn-primary float-end"
                  >
                    {__('G169')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
}

SelectTemplateModal.propTypes = {
  onTemplateSelected: PropTypes.func,
};

export default SelectTemplateModal;
