import React, { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { faCalendarXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, isBefore } from 'date-fns';
import { de } from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import { es } from 'date-fns/locale/es';
import { fr } from 'date-fns/locale/fr';
import { it } from 'date-fns/locale/it';
import { pt } from 'date-fns/locale/pt';
import PropTypes from 'prop-types';

import { updateScheduledEnd } from '../../../../bridge';
import { formatScheduledTime, requiresReleaseMode } from '../../../../helpers/itpmetricsUtils';
import { __ } from '../../../../helpers/translations';
import { getCookieValue } from '../../../../helpers/utils';

import SelectReleaseMode from './SelectReleaseMode';

registerLocale('en', en);
registerLocale('fr', fr);
registerLocale('es', es);
registerLocale('de', de);
registerLocale('it', it);
registerLocale('pt', pt);

function UpdateScheduledEndTimeButton({
  survey = {}, onChanged, disabled, demo,
}) {
  const [showModal, setShowModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [releaseMode, setReleaseMode] = useState(survey.releaseMode);

  const [scheduledStartAt, setScheduledStartAt] = useState(
    survey.scheduledStartAt ? new Date(survey.scheduledStartAt) : addDays(new Date(), 7),
  );

  const onClickSchedule = async () => {
    setInProgress(true);
    const { error } = await updateScheduledEnd(survey.surveyId, scheduledStartAt, releaseMode);
    setInProgress(false);
    if (error) {
      setErrorMessage(error);
      return;
    }
    setShowModal(false);
    onChanged();
  };

  return (
    <>
      <Button
        title={__('G271')}
        size={demo ? 'sm' : 'md'}
        variant="light"
        onClick={() => setShowModal(true)}
        disabled={disabled || demo || inProgress}
      >
        <FontAwesomeIcon icon={faCalendarXmark} />
      </Button>
      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{__('G271')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{__('G272')}</p>
          <div className="text-center">
            <DatePicker
              selected={scheduledStartAt}
              onChange={(date) => setScheduledStartAt(date)}
              timeIntervals={5}
              showTimeSelect
              inline
              locale={getCookieValue('language')}
            />
          </div>
          <div className="text-center fw-bold pb-3">
            <span className={isBefore(scheduledStartAt, new Date()) ? 'text-danger' : 'text-success'}>
              {formatScheduledTime(scheduledStartAt)}
            </span>
          </div>

          {requiresReleaseMode(survey)
          && <SelectReleaseMode initialReleaseMode={releaseMode || survey.releaseMode} onChange={setReleaseMode} />}

          {errorMessage && <Alert variant="danger" className="text-center">{errorMessage}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
            {__('G106')}
          </Button>
          <Button
            variant="primary"
            disabled={inProgress || isBefore(scheduledStartAt, new Date()) || (requiresReleaseMode(survey) && !releaseMode)}
            onClick={onClickSchedule}
          >
            {__('G107')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

UpdateScheduledEndTimeButton.propTypes = {
  survey: PropTypes.shape({
    surveyId: PropTypes.string.isRequired,
    scheduledStartAt: PropTypes.string,
    releaseMode: PropTypes.string,
  }),
  onChanged: PropTypes.func,
  demo: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default UpdateScheduledEndTimeButton;
