/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Card, FormCheck } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

export function ReportConsentSection({ section, respondent, updateResponse }) {
  const [reportResponse, setReportResponse] = useState(respondent.reportResponse || {});

  return (
    <Card className={`pagebreak py-3 my-3 ${section.noPrint ? 'no-print' : ''}`}>
      <Card.Body>
        <div className="m-3 p-3">
          {section.title && <h2>{section.title}</h2>}
          <br className="print-only" />
        </div>
        {section.sections.map((subSection, index) => (
          <div className="m-3 p-3" key={`report-subsection-caption-${subSection.key}`}>
            {subSection.pagebreak && <div className="pagebreak" />}
            {subSection.title && <h4>{subSection.title}</h4>}
            <div>{ReactHtmlParser(subSection.content)}</div>
            <br />
            {subSection.radioList && Object.keys(subSection.radioList).map((key) => (
              <div key={`consent-${subSection.key}-${index}-${key}`} className="pb-4">
                <FormCheck
                  type="radio"
                  key={`consent-options-${subSection.key}-${index}-${key}-radio`}
                  id={`consent-options-${subSection.key}-${index}-${key}-radio`}
                  name={key}
                  value={key}
                  label={subSection.radioList[key]}
                  checked={
                    reportResponse[section.key]
                    && reportResponse[section.key][subSection.key] === key
                  }
                  onChange={() => {
                    const updates = cloneDeep(reportResponse);
                    updates[section.key] = updates[section.key] || {};
                    updates[section.key][subSection.key] = key;
                    setReportResponse(updates);
                    updateResponse(section.key, subSection.key, key);
                  }}
                />
              </div>
            ))}
          </div>
        ))}

      </Card.Body>
    </Card>
  );
}

ReportConsentSection.propTypes = {
  section: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    radioList: PropTypes.object,
    noPrint: PropTypes.bool,
    sections: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    })),
  }),
  respondent: PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    response: PropTypes.object,
    report: PropTypes.object,
    reportResponse: PropTypes.object,
    status: PropTypes.string,
    surveyId: PropTypes.string,
    templateId: PropTypes.string,
    updatedAt: PropTypes.string,
    respondentId: PropTypes.string,
  }),
  response: PropTypes.object,
  updateResponse: PropTypes.func,
};

ReportConsentSection.defaultProps = {
  section: null,
  respondent: {},
  response: {},
  updateResponse: () => {},
};
