/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { updateUserResponses } from '../../../bridge';
import { __ } from '../../../helpers/translations';

function CompleteModal({
  show, onHide, respondentId, completed,
}) {
  const navigate = useNavigate();

  const title = completed ? __('G191')
    : respondentId ? __('G192')
      : __('G188');

  const body = completed ? __('G193')
    : respondentId ? __('G194')
      : __('G189');

  const button = completed || respondentId ? __('G107') : __('G190');

  const handleClick = async () => {
    if (completed) {
      navigate('/dashboard');
    } else if (respondentId) {
      await updateUserResponses(respondentId, {}, true);
      navigate(`/report/${respondentId}`);
    } else {
      navigate('/');
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        if (completed) {
          navigate('/dashboard');
        } else {
          onHide();
        }
      }}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClick}>{button}</Button>
      </Modal.Footer>
    </Modal>
  );
}

CompleteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  respondentId: PropTypes.string,
  completed: PropTypes.bool,
};

CompleteModal.defaultProps = {
  show: false,
  onHide: () => {},
  respondentId: null,
  completed: false,
};

export default CompleteModal;
