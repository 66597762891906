import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { __ } from '../../../../helpers/translations';
import AddReviewParticipantModal from '../modals/AddReviewParticipantModal';

function AddReviewParticipantButton({ reviewId, onChanged, demo }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        title={__('G265')}
        size={demo ? 'sm' : 'md'}
        variant="light"
        disabled={demo}
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faUserPlus} />
      </Button>
      {showModal && (
      <AddReviewParticipantModal
        reviewId={reviewId}
        onCancel={() => setShowModal(false)}
        onChanged={async () => {
          setShowModal(false);
          await onChanged();
        }}
      />
      )}
    </>

  );
}

AddReviewParticipantButton.propTypes = {
  reviewId: PropTypes.string.isRequired,
  onChanged: PropTypes.func,
  demo: PropTypes.bool,
};

export default AddReviewParticipantButton;
