import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Container, Navbar, Row,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import logo from '../../../assets/itpmetrics/logo_square.png';
import { topNavbarBreakpoint } from '../../../config';
import AppContext from '../../../context/Context';
import handleNavbarTransparency from '../../../helpers/handleNavbarTransparency';

import LandingRightSideNavItem from './LandingRightSideNavItem';

function NavbarStandard({ unfixed }) {
  const {
    config: { isDark },
  } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const { pathname } = useLocation();
  const hideNavigation = pathname.startsWith('/feedback.review/');

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      variant={isDark ? 'light' : 'dark'}
      fixed={unfixed ? undefined : 'top'}
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-100': (!navbarCollapsed || unfixed) && isDark,
        'bg-dark': (!navbarCollapsed || unfixed) && !isDark,
      })}
      style={{ marginBottom: '1em' }}
    >
      <Container>
        <Navbar.Brand className="text-white dark__text-white" as={Link} to="/">
          <Row className="g-0">
            <Col>
              <img src={logo} width="40" alt="ITP Metrics" />
            </Col>
            <Col style={{ marginLeft: '6px', marginTop: '6px' }}>
              ITP Metrics
            </Col>
          </Row>
        </Navbar.Brand>
        {!hideNavigation && (
        <>
          <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
          <Navbar.Collapse>
            <LandingRightSideNavItem />
          </Navbar.Collapse>
        </>
        )}

      </Container>
    </Navbar>
  );
}

NavbarStandard.propTypes = {
  unfixed: PropTypes.bool,
};

NavbarStandard.defaultProps = {
  unfixed: true,
};

export default NavbarStandard;
