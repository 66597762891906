import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { last } from 'lodash-es';
import PropTypes from 'prop-types';

function AdminCard({ card }) {
  const [, copyToClipboard] = useCopyToClipboard();
  const [verificationLink, setVerificationLink] = useState(null);
  const [expiredAccessCode, setExpiredAccessCode] = useState(false);
  const getSurveyLink = () => `${window.location.origin}/survey/${card.surveyId}`;

  useEffect(() => {
    if (!card) {
      return;
    }
    const entry = last(card.verificationCodes);
    if (!entry) {
      return;
    }
    setExpiredAccessCode(new Date(entry.expiryDate) < new Date());
    setVerificationLink(`${window.location.origin}/verify?email=${card.email}&code=${entry.code}`);
  }, [card]);

  return (
    <Card className="m-1 p-1 h-100">
      <Card.Body className="p-2">
        <Row className="h-100">
          <Col>
            {(card.firstName || card.lastName) && (
            <Card.Text>
              {`${card.firstName} ${card.lastName}`}
            </Card.Text>
            )}

            {verificationLink && (
              <Card.Text
                className="text-muted small"
                style={{ cursor: 'pointer' }}
                onClick={() => copyToClipboard(verificationLink)}
              >
                Copy access link
              </Card.Text>
            )}
            { expiredAccessCode && (
            <Card.Text
              className="text-muted small"
              style={{ cursor: 'pointer' }}
              onClick={() => copyToClipboard(`${verificationLink}&muted=true`)}
            >
              Copy muted access link
            </Card.Text>
            )}
            { card.email && (
            <Card.Text
              className="text-muted small"
              onClick={() => copyToClipboard(card.email)}
              style={{ cursor: 'pointer' }}
            >
              Copy
              {' '}
              {card.email}
            </Card.Text>
            )}

            { card.templateId && (
            <Card.Text>{card.name}</Card.Text>
            )}

            {card.surveyId && (
              <Row>
                <Col>
                  <Card.Text
                    className="text-muted small"
                    style={{ cursor: 'pointer' }}
                    onClick={() => copyToClipboard(getSurveyLink())}
                  >
                    Copy survey link
                  </Card.Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

AdminCard.propTypes = {
  card: PropTypes.object,
};

export default AdminCard;
