import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import PropTypes from 'prop-types';

import { __ } from '../../../helpers/translations';
import { getCookieValue } from '../../../helpers/utils';

export default function DateSelection({ setFormData, formData, user }) {
  const [selectedMonth, setMonth] = useState(user.dateOfBirth ? new Date(user.dateOfBirth).getMonth() : '');
  const [selectedDay, setDay] = useState(user.dateOfBirth ? new Date(user.dateOfBirth).getDate() : '');
  const [selectedYear, setYear] = useState(user.dateOfBirth ? new Date(user.dateOfBirth).getFullYear() : '');

  useEffect(() => {
    if (selectedMonth !== '' && selectedDay !== '' && selectedYear !== '') {
      setFormData({
        ...formData,
        dateOfBirth: new Date(selectedYear, selectedMonth, selectedDay),
      });
    }
  }, [selectedMonth, selectedDay, selectedYear]);

  const monthString = (monthInt) => {
    if (monthInt === '') {
      return '';
    }
    const date = new Date();
    date.setMonth(monthInt);
    let locale = getCookieValue('language') || 'en';
    if (locale === 'en') {
      locale = 'default';
    }
    return date.toLocaleString(locale, { month: 'long' });
  };

  const monthObjects = [
    { id: '' },
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
  ].map((item) => ({ ...item, label: monthString(item.id) }));

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if ((name === 'day') && (value.length < 0 || value.length > 2 || value === '0')) {
      return;
    }
    if ((name === 'year') && value.length > 4) {
      return;
    }
    switch (name) {
      case 'month':
        setMonth(value);
        break;
      case 'year':
        setYear(value);
        break;
      case 'day':
        setDay(value);
        break;
      default:
    }
  };

  return (
    <Row>
      <Col sm={4}>
        <FloatingLabel controlId="floatingSelect" label={__('G71')}>
          <Form.Select
            value={selectedMonth}
            name="month"
            onChange={handleChange}
            type="select"
            selected={selectedMonth}
            aria-label={__('G71')}
          >
            {monthObjects.map((month) => (
              <option key={month.id} value={month.id}>{month.label}</option>
            ))}
          </Form.Select>
        </FloatingLabel>
      </Col>
      <Col sm={4} className="m-0">
        <FloatingLabel controlId="floatingSelect" label={__('G72')}>
          <Form.Control
            type="number"
            min="1"
            max="31"
            maxLength={2}
            minLength={1}
            value={selectedDay}
            name="day"
            onChange={handleChange}
            aria-label={__('G72')}
          />
        </FloatingLabel>
      </Col>
      <Col sm={4}>
        <FloatingLabel controlId="floatingSelect" label={__('G73')}>
          <Form.Control
            aria-label={__('G73')}
            type="number"
            min="1900"
            max="2100"
            maxLength={4}
            minLength={4}
            name="year"
            value={selectedYear}
            onChange={handleChange}
          />
        </FloatingLabel>
      </Col>
    </Row>

  );
}

DateSelection.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.any,
  user: PropTypes.shape({
    dateOfBirth: PropTypes.string,
  }),
};

DateSelection.defaultProps = {
  setFormData: () => {},
  formData: {},
  user: {},
};
