import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { __ } from '../../../helpers/translations';
import Section from '../../common/Section';

import SectionHeader from './SectionHeader';

const processes = [
  {
    title: __('G7'),
    description: __('G8'),
  },
  {
    title: __('G9'),
    description: __('G10'),
  },
  {
    title: __('G11'),
    description: __('G12'),
  },
];

function Processes() {
  return (
    <Section>
      <SectionHeader
        title={__('G5')}
        subtitle={__('G6')}
      />
      <Row className="my-7">
        { processes.map(({ title, description }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col lg={4} className="my-2" key={`landing-process-index-${index}`}>
            <h3>{title}</h3>
            <p>{description}</p>
          </Col>
        ))}
      </Row>

    </Section>
  );
}

export default Processes;
