import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { endSurvey } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';

function EndSurveyButton({ survey, onChanged, disabled }) {
  const [showModal, setShowModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const onClickEnd = async () => {
    setInProgress(true);
    await endSurvey(survey.surveyId);
    setInProgress(false);
    setShowModal(false);
    onChanged();
  };

  return (
    <>
      <Button
        title={__('G108')}
        size="md"
        variant="light"
        onClick={() => setShowModal(true)}
        disabled={disabled || inProgress}
      >
        <FontAwesomeIcon icon={faStop} />
      </Button>
      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{__('G108')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{__('G109')}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
            {__('G106')}
          </Button>
          <Button variant="primary" disabled={inProgress} onClick={onClickEnd}>
            {__('G107')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

EndSurveyButton.propTypes = {
  survey: PropTypes.shape({
    surveyId: PropTypes.string.isRequired,
  }),
  onChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

export default EndSurveyButton;
