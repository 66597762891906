import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { merge } from 'lodash-es';
import PropTypes from 'prop-types';

import { updateUserResponses } from '../../../../bridge';
import { logError } from '../../../../helpers/logger';
import { __ } from '../../../../helpers/translations';
import CompleteModal from '../../common/CompleteModal';

import Caption from './inputs/Caption';
import Consent from './inputs/Consent';
import ConsentOptions from './inputs/ConsentOptions';
import MultipleChoice from './inputs/MultipleChoice';
import PeerRanged from './inputs/PeerRanged';
import PeerRangedText from './inputs/PeerRangedText';
import TeamText from './inputs/TeamText';
import { defaultPeers } from './PreviewDefaults';

function SurveyBody({
  template, respondentId, pageIndex, isPreview, respondent, peers,
}) {
  const navigate = useNavigate();
  const { templateId, pages } = template;
  const currentPage = pages[pageIndex];
  const [showComplete, setShowComplete] = useState(false);
  const [response, setResponse] = useState((respondent && respondent.response) || {});
  const [changes, setChanges] = useState({});
  const navigateWithContext = (destPageIndex) => {
    if (isPreview) {
      navigate(`/template/${templateId}/${destPageIndex}`);
    } else {
      navigate(`/respondent/${respondentId}/${destPageIndex}`);
    }
  };

  const updateResponses = async () => {
    if (Object.keys(changes).length === 0) {
      return;
    }

    if (!isPreview) {
      const { error } = await updateUserResponses(respondentId, changes);
      if (error) {
        logError('survey_body_update_responses', error);
        return;
      }
    }
    setResponse(merge({ ...response }, changes));
    setChanges({});
  };

  const handleNext = async (skip) => {
    await updateResponses();
    const nextPageIndex = pageIndex + 1;
    if (nextPageIndex >= pages.length) {
      setShowComplete(true);
    } else {
      navigateWithContext(nextPageIndex + (skip ? 1 : 0));
    }
  };

  const handleBack = async () => {
    await updateResponses();
    navigateWithContext(Math.max(pageIndex - 1, 0));
  };

  useEffect(() => {
    updateResponses();
  }, [changes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageIndex]);

  useEffect(() => {
    if (respondent.status === 'completed') {
      setShowComplete(true);
    }
  }, []);

  return (
    <>
      <CompleteModal
        show={showComplete}
        onHide={() => setShowComplete(false)}
        respondentId={respondentId}
        completed={respondent.status === 'completed'}
      />
      <Card className="my-2">
        <Card.Body>
          <Caption data={currentPage} response={response} />

          <TeamText
            data={currentPage}
            response={response}
            onChanged={(delta) => setChanges(merge({ ...changes }, delta))}
          />

          <MultipleChoice
            data={currentPage}
            response={response}
            setResponse={setResponse}
            changes={changes}
            setChanges={setChanges}
          />

          <Consent
            data={currentPage}
            response={response}
            onChanged={(delta) => setChanges(merge({ ...changes }, delta))}
          />

          <ConsentOptions
            data={currentPage}
            response={response}
            onChanged={(delta) => setChanges(merge({ ...changes }, delta))}
          />

          <PeerRanged
            data={currentPage}
            peers={peers.length === 0 ? defaultPeers : peers}
            response={response}
            onChanged={(delta) => setChanges(merge({ ...changes }, delta))}
          />

          <PeerRangedText
            data={currentPage}
            peers={peers.length === 0 ? defaultPeers.filter((peer) => peer.firstName !== 'Self') : peers.filter((peer) => peer.respondentId !== respondentId)}
            response={response}
            onChanged={(delta) => setChanges(merge({ ...changes }, delta))}
          />

        </Card.Body>
        <Card.Footer>
          {pageIndex > 0 && (
          <Button variant="outline-secondary" onClick={handleBack} aria-label={__('G48')}>
            {__('G48')}
          </Button>
          )}

          {currentPage[0].skipNext && (
          <Button onClick={() => handleNext(true)} variant="secondary" className="float-end mx-2" aria-label={__('G50')}>
            {currentPage[0].skipNextText}
          </Button>
          )}
          <Button
            onClick={() => handleNext(false)}
            className="float-end"
            aria-label={pageIndex === pages.length - 1
              ? __('G49')
              : __('G47')}
          >
            {pageIndex === pages.length - 1
              ? __('G49')
              : __('G47')}
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

SurveyBody.propTypes = {
  template: PropTypes.shape({
    templateId: PropTypes.string,
    name: PropTypes.string,
    pages: PropTypes.arrayOf(PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        content: PropTypes.string,
        skipNext: PropTypes.bool,
        skipNextText: PropTypes.string,
      }),
    )),
  }),
  respondentId: PropTypes.string,
  pageIndex: PropTypes.number,
  isPreview: PropTypes.bool,
  respondent: PropTypes.shape({
    response: PropTypes.object,
    status: PropTypes.string,
  }),
  peers: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    respondentId: PropTypes.string,
  })),
};

SurveyBody.defaultProps = {
  template: null,
  respondentId: null,
  pageIndex: 0,
  isPreview: false,
  respondent: {},
  peers: [],
};

export default SurveyBody;
