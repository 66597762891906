import React, { useEffect, useState } from 'react';
import {
  Button, Card,
  Col, Form, Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

import { getPrivacyPolicy, saveProfile } from '../../../bridge';
import { __ } from '../../../helpers/translations';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLogin } from '../../../hooks/useLogin';
import ContentSection from '../common/ContentSection';
import Loading from '../common/Loading';
import Page from '../common/Page';
import SignedOut from '../common/SignedOutDialog';

import DateSelection from './DateSelection';
import PrivacyPolicy from './PrivacyPolicy';

function Profile({ hasLabel }) {
  const { handleDispatch } = useLogin();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [policyId, setPolicyId] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    organization: '',
    policyId: '',
  });

  const loadPrivacy = async () => {
    const { response } = await getPrivacyPolicy();
    setPolicyId(response.policyId);
    setPrivacyPolicy(response.content);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    const diff = {};
    Object.keys(formData).forEach((key) => {
      if (user[key]) {
        diff[key] = user[key];
      }
    });
    setFormData({ ...formData, ...diff });
  }, [user]);

  useEffect(() => {
    if (!privacyPolicy) {
      loadPrivacy();
    }
  }, [privacyPolicy]);

  // Handler
  const handleSubmit = async () => {
    setInProgress(true);
    const { response, error } = await saveProfile(formData);
    setInProgress(false);
    if (error) {
      toast.error(error, {
        theme: 'colored',
      });
    } else {
      handleDispatch(response.token);
      navigate('/dashboard');
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const genders = [
    { id: '', title: __('G80') },
    { id: 'male', title: __('G75') },
    { id: 'female', title: __('G76') },
    { id: 'non-binary', title: __('G77') },
    { id: 'prefer-not-to-say', title: __('G78') },
  ];

  if (!user) {
    return <SignedOut />;
  }

  if (!privacyPolicy) {
    return <Loading />;
  }
  return (
    <Page>
      <ContentSection>
        <ToastContainer />
        <Card className="rounded" style={{ maxWidth: '700px', margin: '0 auto' }}>
          <Card.Body className="FormCard">
            <h4 className="my-2">{__('G58')}</h4>
            <Row>
              <Form.Group className="mb-3" as={Col} sm={6}>
                {__('G59')}
                {hasLabel && <Form.Label>{__('G59')}</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? __('G60') : ''}
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleFieldChange}
                  type="text"
                  required
                  isInvalid={!formData.firstName}
                  autoFocus
                  aria-label={__('G59')}
                />
                {!formData.firstName && (
                  <Form.Control.Feedback type="invalid">
                    {__('G60')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3" as={Col} sm={6}>
                {__('G61')}
                {hasLabel && <Form.Label>{__('G61')}</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? __('G62') : ''}
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleFieldChange}
                  type="text"
                  required
                  isInvalid={!formData.lastName}
                  aria-label={__('G61')}
                />
                {!formData.lastNameName && (
                  <Form.Control.Feedback type="invalid">
                    {__('G62')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  {__('G63')}
                  {hasLabel && <Form.Label>{__('G63')}</Form.Label>}
                  <Form.Select
                    placeholder={__('G64')}
                    value={formData.gender}
                    name="gender"
                    onChange={handleFieldChange}
                    type="select"
                    aria-label={__('G63')}
                  >
                    {genders.map((gender) => (
                      <option key={gender.id} value={gender.id}>{gender.title}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  {__('G65')}
                  {hasLabel && <Form.Label>{__('G65')}</Form.Label>}
                  <Form.Control
                    placeholder={!hasLabel ? __('G66') : ''}
                    value={formData.organization}
                    name="organization"
                    onChange={handleFieldChange}
                    type="text"
                    aria-label={__('G65')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Form.Group>
                {__('G67')}
                {hasLabel && <Form.Label>{__('G67')}</Form.Label>}
                <div>
                  <DateSelection setFormData={setFormData} formData={formData} user={user} />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <div>
                  {user?.privacyPolicy}
                  <PrivacyPolicy formData={formData} setFormData={setFormData} privacyPolicy={privacyPolicy} policyId={policyId} />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Col className="col-auto ms-auto">
                <Button
                  disabled={inProgress || !formData.firstName || !formData.lastName || !formData.policyId}
                  onClick={handleSubmit}
                >
                  {__('G74')}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </ContentSection>
    </Page>
  );
}

Profile.propTypes = {
  hasLabel: PropTypes.bool,
};

export default Profile;
