import React from 'react';
import { Card, Row } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

import { ReportBarChart } from './ReportBarChart';
import { ReportBarChartExpress } from './ReportBarChartExpress';

export function ReportPeerFeedbackExpressSection({ respondent }) {
  const { report } = respondent;

  return (Object.keys(report).map((key) => (
    <Card key={`report-section-express-${key}`} className="pagebreak p-4 py-3 my-3">
      <Card.Body>
        <Row>
          <h4>{report[key].Caption}</h4>
        </Row>
        <Row>
          <div>
            <h5>{report[key].ImprovementTitle}</h5>
            {ReactHtmlParser(report[key].ImprovementDescription)}
          </div>
        </Row>
        <Row>
          {key === 'Commitment'
            ? (
              <ReportBarChartExpress
                peerRating={String(report[key].peer)}
                selfRating={String(report[key].self)}
                groupRating={String(report[key].group)}
              />
            )
            : (
              <ReportBarChart
                peerRating={String(report[key].peer)}
                selfRating={String(report[key].self)}
              />
            )}
        </Row>
        <Row>
          <h5>{report[key].Header}</h5>
          <div className="m-3 p-3">
            <div>{ReactHtmlParser(report[key].Improvement)}</div>
          </div>
        </Row>
      </Card.Body>
    </Card>
  )));
}

ReportPeerFeedbackExpressSection.propTypes = {
  respondent: PropTypes.shape({
    report: PropTypes.object,
  }),
};
