import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ClampLines from 'react-clamp-lines';
import PropTypes from 'prop-types';

import { getReviewParticipant } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';

import ReviewCardDropDown from './ReviewCardDropDown';

function ReviewCard({ reviewId, participant, onChanged }) {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(participant.link);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { response } = await getReviewParticipant(reviewId, participant.reviewParticipantId);
      setLoading(false);
      if (!response) {
        setError(true);
        return;
      }
      setLink(response.link);
    };
    if (link) {
      return;
    }
    getData();
  }, [reviewId]);

  return (
    <Card className="m-2 p-2 h-100">
      <Card.Body className="p-2">
        <Row className="h-100">
          <Col className="d-flex flex-column">
            <ClampLines
              text={`${participant.firstName} ${participant.lastName}`}
              lines={1}
              buttons={false}
              className="respondent-card-title"
            />
            <Card.Text className="text-muted small">{participant.email}</Card.Text>
            <small>
              {error && <div>{__('G184')}</div>}
              {link && <a href={link} target="_blank" rel="noreferrer">{__('G186')}</a>}
              {loading && <div>{__('G185')}</div>}
            </small>
          </Col>
        </Row>
        <div className="position-absolute" style={{ left: '91%', top: '13%' }}>
          <ReviewCardDropDown
            onChanged={onChanged}
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            reviewId={reviewId}
            reviewParticipantId={participant.reviewParticipantId}
          />
        </div>
      </Card.Body>
    </Card>
  );
}

ReviewCard.propTypes = {
  reviewId: PropTypes.string,
  participant: PropTypes.object,
  onChanged: PropTypes.func,
};

export default ReviewCard;
