/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Form } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

function Consent({
  data,
  response,
  onChanged,
}) {
  const items = data.filter(({ type }) => type === 'consent');
  if (items.length === 0) {
    return null;
  }

  return (
    <>
      {items.map((item) => (
        <div key={`consent-${item.key}`}>
          <div className="p-3">
            {Object.values(item.contentList).map(
              (content, index) => (
                <div key={`consent-${item.key}-${index}`} className="pb-4">
                  {index === item.checklistIndex && Object.keys(item.checklist).map((key) => (
                    <div key={`consent-${item.key}-${index}-${key}`} className="pb-4">
                      <Form.Check
                        type="checkbox"
                        id={`consent-${item.key}-${index}-${key}-checkbox`}
                        label={item.checklist[key]}
                        checked={!!(response && response[key])}
                        onChange={(event) => onChanged({ [key]: event.target.checked })}
                      />
                    </div>
                  ))}
                  {ReactHtmlParser(content)}
                </div>
              ),
            )}
          </div>
        </div>

      ))}
    </>
  );
}

Consent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      contentList: PropTypes.array,
      type: PropTypes.string,
    }),
  ),
  response: PropTypes.object,
  onChanged: PropTypes.func,
};

Consent.defaultProps = {
  data: [],
  response: {},
  onChanged: () => {},
};

export default Consent;
