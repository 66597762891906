import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { deleteReviewParticipant, sendReviewEmail } from '../../../../bridge';
import { logError } from '../../../../helpers/logger';
import { __ } from '../../../../helpers/translations';

function ReviewCardDropDown({
  reviewId, onChanged, showPopup, setShowPopup, reviewParticipantId,
}) {
  const handleToggleClick = (e) => {
    e.stopPropagation();
    setShowPopup(!showPopup);
  };

  const onClickDelete = async (e) => {
    e.stopPropagation();
    setShowPopup(false);
    try {
      const { error } = await deleteReviewParticipant(reviewId, reviewParticipantId);
      if (error) {
        logError('review_card_drop_down_on_click_delete', error);
      } else {
        onChanged();
      }
      setShowPopup(false);
    } catch (error) {
      logError('review_card_drop_down_on_click_delete_catch', error);
    }
  };

  const onClickResend = async (e) => {
    e.stopPropagation();
    setShowPopup(false);
    try {
      const { error } = await sendReviewEmail(reviewId, reviewParticipantId);
      if (error) {
        logError('review_card_drop_down_on_click_resend', error);
      } else {
        onChanged();
      }
      setShowPopup(false);
    } catch (error) {
      logError('review_card_drop_down_on_click_resend_catch', error);
    }
  };

  const options = [
    {
      key: 'resend',
      icon: faPaperPlane,
      caption: __('G163'),
      disableOnCompleted: true,
      callback: onClickResend,
    },
    {
      key: 'delete',
      icon: faTrashAlt,
      caption: __('G162'),
      disableOnCompleted: true,
      callback: onClickDelete,
    },
  ];

  return (
    <Dropdown
      show={showPopup}
      onToggle={(isOpen) => setShowPopup(isOpen)}
    >
      <FontAwesomeIcon
        icon={faEllipsisV}
        onClick={handleToggleClick}
        className="p-1 cursor-pointer"
      />
      <Dropdown.Menu className="p-0 m-0">
        {options.map((option) => (
          <Dropdown.Item
            key={option.key}
            onClick={(e) => option.callback(e)}
            className="py-1 px-2 m-0"
          >
            <FontAwesomeIcon icon={option.icon} className="me-2" />
            {option.caption}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

ReviewCardDropDown.propTypes = {
  reviewId: PropTypes.string,
  onChanged: PropTypes.func,
  showPopup: PropTypes.bool,
  setShowPopup: PropTypes.func,
  reviewParticipantId: PropTypes.string,
};

export default ReviewCardDropDown;
