import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getLegacyReport } from '../../../bridge';
import { getCopyrightMessage } from '../../../helpers/itpmetricsUtils';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ContentSection from '../common/ContentSection';
import Loading from '../common/Loading';
import Page from '../common/Page';
import SignedOut from '../common/SignedOutDialog';

import ReportBody from './ReportBody';
import ReportError from './ReportError';
import ReportNotReady from './ReportNotReady';

function LegacyReport() {
  const { user } = useAuthContext();
  const [result, setResult] = useState(null);
  const { respondentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [reportError, setReportError] = useState(null);
  const [reportNotReady, setReportNotReady] = useState(null);

  useEffect(() => {
    if (!respondentId) {
      return;
    }
    const fetchReport = async () => {
      const r = await getLegacyReport(respondentId);
      await setLoading(false);
      if (r.error) {
        await setReportError(r.error);
        return;
      }
      if (r.response.reportNotReady) {
        setReportNotReady(r.response);
        return;
      }
      await setResult(r.response);
    };
    fetchReport();
  }, [respondentId]);

  if (!user && !(new URLSearchParams(document.location.search).get('token'))) {
    return <SignedOut />;
  }

  if (loading) {
    return <Loading />;
  }

  if (reportError) {
    return <ReportError error={reportError} />;
  }

  if (reportNotReady) {
    return <ReportNotReady report={reportNotReady} />;
  }

  if (!result) {
    return null;
  }

  return (
    <Page>
      <ContentSection>
        <ReportBody
          reportSections={result.reportSections}
          respondent={result.respondent}
          survey={result.survey}
          templateMode={false}
          disableReportResponse
        />
        <footer className="justify-content-center">
          {getCopyrightMessage()}
        </footer>
      </ContentSection>
    </Page>
  );
}

export default LegacyReport;
