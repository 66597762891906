import React from 'react';
import { Card } from 'react-bootstrap';
import ClampLines from 'react-clamp-lines';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import InfoPopover from '../common/InfoPopover';
import StatusPill from '../common/StatusPill';

import './DashboardCard.scss';

function DashboardCard({ item }) {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => {
        if (item.type === 'survey') {
          navigate(`/survey/${item.surveyId}`);
          return;
        }

        if (item.type === 'review') {
          navigate(`/review/${item.reviewId}`);
          return;
        }

        if (item.status === 'completed' || item.surveyStatus === 'ended') {
          navigate(`/report/${item.respondentId}`);
          return;
        }

        navigate(`/respondent/${item.respondentId}/0`);
      }}
      className="mx-2 my-3 dashboard-card"
    >
      <Card.Body>
        <>
          <ClampLines
            text={item.name}
            lines={2}
            buttons={false}
            className="dashboard-card-title"
          />
          <div style={{
            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.8em',
          }}
          >
            {item.templateName}
          </div>
          {
            item.type === 'respondent'
            && item.surveyStatus !== 'ended'
            && (item.status === 'not-started' || item.status === 'in-progress')
            && (
            <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle" />
            )
          }
          <div style={{ position: 'absolute', bottom: '1em', width: '100%' }}>
            {item.type === 'survey' && <StatusPill status={item.status} />}
            {item.type === 'respondent' && <StatusPill status={item.status} surveyStatus={item.surveyStatus} />}
            {item.type === 'respondent' && (
              <InfoPopover item={item} />
            )}
          </div>
        </>
      </Card.Body>
    </Card>
  );
}

DashboardCard.propTypes = {
  item: PropTypes.object,
};

export default DashboardCard;
