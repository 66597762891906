import React, { useState } from 'react';
import {
  Alert, Button, Form, Modal,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { updateSurveyName } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';

function SurveyNameModal({
  surveyId, name, onChanged, onCancel,
}) {
  const [text, setText] = useState(name);
  const [isNameEmpty, setIsNameEmpty] = useState(false);

  const onClickSave = async () => {
    if (text.trim() === '') {
      setIsNameEmpty(true);
      return;
    }
    await updateSurveyName(surveyId, text.trim());
    setIsNameEmpty(false);
    onChanged();
  };

  return (
    <Modal show centered onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>{__('G113')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="survey-name">
          <Form.Control
            type="text"
            autoFocus
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder={__('G113')}
            onFocus={(e) => e.target.select()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClickSave();
              }
            }}
          />
        </Form.Group>
        {isNameEmpty && (
          <Alert variant="danger">{__('G114')}</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onCancel}>
          {__('G106')}
        </Button>
        <Button variant="primary" onClick={onClickSave}>
          {__('G107')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SurveyNameModal.propTypes = {
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
  surveyId: PropTypes.string,
};

export default SurveyNameModal;
